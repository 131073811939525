

//----------------------------------------------------/
// The block number above each block
//----------------------------------------------------/
.block-number {
  $color: #191919;

  text-align: center;
  font-weight: bold;
  font-size: 0.75em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $color;
  //opacity: 0.8;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6px;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid $color;
  }

  a:hover,
  a:focus {
    color: $color;
  }
}


//----------------------------------------------------/
// Make some change to components in demo
//----------------------------------------------------/
.demo-section .main-content .section,
.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar,
.demo-header .main-content .header,
.demo-footer .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
  box-shadow: 0 0 15px rgba(0,0,0,0.05);
}

.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar {
  position: static;
  margin: 0;
}


//----------------------------------------------------/
// Demo image preview
//----------------------------------------------------/
.demo-preview {

  margin-bottom: 3rem;

  .card-img-top {
    background-position: center 0px;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 245px;
    transition: 5s linear;
  }

  &:hover .card-img-top {
    background-position: center 100%;
  }

  .card-body {
    border-top: 1px solid #f3f4f5;
    background-color: #fcfdfe;
  }
}


//----------------------------------------------------/
// Footer responsive
//----------------------------------------------------/
@media (max-width: 400px) {
  body > .footer {
    .social a:last-child,
    .nav a:last-child {
      display: none;
    }
  }
}

